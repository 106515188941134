import { combineReducers } from 'redux';
import { reducer as toastr } from 'react-redux-toastr';
import app from './app';
import customer from './customer';
import category from './category';
import template from './template';
import landingPage from './landingPage';
import campaign from './campaign';
import dashboard from './dashboard';
import employee from './employee';
import user from './user';
import training from './training';
import scorm from './scorm';
import confirmBox from './confirmBox';
import group from './group';
import systemTemplate from './systemTemplate';
import trainingReminder from './trainingReminder';
import reporter from './reporter';
import poster from './poster';
import poster_category from './poster_category';
import tokenReducer from './tokenReducer';

export default combineReducers({
  app,
	toastr,
  customer,
  category,
  template,
  landingPage,
  campaign,
  dashboard,
  employee,
  user,
  training,
  scorm,
  confirmBox,
  group,
  systemTemplate,
  trainingReminder,
  reporter,
  poster,
  poster_category,
  tokenReducer,
});