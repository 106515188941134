import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { CSpinner } from '@coreui/react-pro';
import { API_URL, FAVICON_URL } from './utils/constant';
import { createKeycloakInstance, getKeycloakLogoutUrl } from './config/keycloak';
import './scss/style.scss';

const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
const Login = React.lazy(() => import('./views/login/Login'));
const ForgotPassword = React.lazy(() => import('./views/forgotPassword/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/resetPassword/ResetPassword'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

const App = () => {
  // eslint-disable-next-line no-unused-vars
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [keycloak, setKeycloak] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeApp = async () => {
      // Set favicon
      let link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = FAVICON_URL;

      // Check URL parameters if not in localStorage
      const urlParams = new URLSearchParams(window.location.search);
      const urlRealm = urlParams.get('realm');
      const urlClientId = urlParams.get('clientId');
      const urlCustomerName = urlParams.get('customerName');

      // Update values if found in URL
      if (urlRealm && urlClientId && urlCustomerName) {
        var realm = urlRealm;
        var clientId = urlClientId;
         var customerName = urlCustomerName;
        
        // Store in localStorage
        localStorage.setItem('realm', realm);
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('customerName', customerName);
      }

      // Initialize Keycloak if we have all required parameters
      if (realm && clientId && customerName) {
        try {
          const keycloakInstance = createKeycloakInstance(realm, clientId, customerName);
          setKeycloak(keycloakInstance);
          const authenticated = await keycloakInstance.init({
            onLoad: 'login-required',
            silentCheckSsoRedirectUri: window.location.origin,
            checkLoginIframe: false,
          });

          setIsAuthenticated(authenticated);

          if (authenticated) {
            const email = keycloakInstance.tokenParsed?.email;
            const family_name = keycloakInstance.tokenParsed?.family_name;
            const given_name = keycloakInstance.tokenParsed?.given_name;
            const name = keycloakInstance.tokenParsed?.name;
            const customerID = keycloakInstance.tokenParsed?.customerID;

            if (email) {
              try {
                const response = await fetch(`${API_URL}/login-keycloak`, {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${keycloakInstance.token}`,
                  },
                  body: JSON.stringify({ email , family_name , given_name , name , customerName , customerID }),
                });
                console.log(">>>>> API URL:",API_URL)

                if (response.status === 404) {
                  alert('Client not found in Phishing Database');
                  const logoutUrl = getKeycloakLogoutUrl();
                  if (logoutUrl) {
                    window.location.href = logoutUrl;
                    return;
                  }
                }

                const { data } = await response.json();
                if (data.authToken && data.user) {
                  localStorage.setItem('authToken', data.authToken);
                  localStorage.setItem('currentCustomer', data.user._id);
                  window.keycloakInstance = keycloakInstance;
                  
                  const currentPath = window.location.pathname;
                  if (currentPath === '/login') {
                    window.location.href = '/dashboard';
                  }
                } else {
                  console.error('Invalid response data:', data);
                }
              } catch (error) {
                console.error('API call error:', error);
              }
            }
          }
        } catch (error) {
          console.error('Keycloak initialization error:', error);
        }
      } else {
        console.warn('Missing required parameters. Please provide realm, clientId, and customerName.');
      }
      setIsLoading(false);
    };

    initializeApp();
  }, []);

  if (isLoading) {
    return <CSpinner color="primary" />;
  }

  return (
    <BrowserRouter>
      <Suspense fallback={<CSpinner color='primary' />}>
        {keycloak ? (
          <Routes>
            <Route path='/login' exact={true} name='Login' element={<Login />} />
            <Route path='/404' exact={true} name='Page 404' element={<Page404 />} />
            <Route path='/500' exact={true} name='Page 500' element={<Page500 />} />
            <Route path='/' element={<Navigate to='/dashboard' replace />} />
            <Route path='*' name='Home' element={<DefaultLayout />} />
          </Routes>
        ) : (
          <Routes>
          <Route path='/forgot/password' exact={true} name='Forgot Password' element={<ForgotPassword />} />
          <Route path='/reset/password' exact={true} name='Reset Password' element={<ResetPassword />} />
            <Route path='/login' exact={true} name='Login' element={<Login />} />
            <Route path='/' element={<Navigate to='/login' replace />} />
            <Route path='*' name='Home' element={<DefaultLayout />} />
          </Routes>
        )}
      </Suspense>
    </BrowserRouter>
  );
};

export default App;